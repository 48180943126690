import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="spacing">
      <h1>Privacy Policy</h1>

      <p>Questionable.io understands the importance of protecting your personal information and undertakes to adhere to this privacy policy at all times to ensure your personal information is effectively safeguarded.</p>

      <h2>What type of personal information we collect</h2>

      <p>Questionable.io undertakes to collect your personal information only where it is necessary for the purposes of providing our services and where requested distributing newsletters, licensing our services, updating our services and responding to sales and technical queries.</p>

      <p>In most circumstances this information will be limited to your contact details, including but not limited to your name, address, e-mail address, company name, company address, your job title and phone numbers. Where orders are made online using credit card details, all information is securely transmitted in an encrypted format to our designated payment services provider.</p>

      <h2>When we collect personal information</h2>

      <p>Questionable.io collects personal information any time you provide it in a form to us and when you complete one of the following actions:</p>

      <ul>
        <li>Create a user account for an Questionable.io service;</li>
        <li>Subscribe to an Questionable.io service;</li>
        <li>Make an enquiry about an Questionable.io service;</li>
        <li>Apply to test a prerelease version of an Questionable.io service;</li>
        <li>Apply to receive a newsletter related to an Questionable.io service;</li>
        <li>Start a technical support query regarding an Questionable.io service;</li>
        <li>Participate in Questionable.io promotional activities or surveys;</li>
        <li>Forward information to us in an email or as a hard copy document; and</li>
        <li>Other circumstances where collecting personal information is reasonable and permitted by the Laws of the United States of America.</li>
      </ul>

      <h2>Use of Cookies</h2>

      <p>Questionable.io may from time to time use cookies. A cookie is a piece of text that is saved to your browser by a web site to enable that website to save certain information to your machine so that it can later be retrieved. We do not use cookies to collect personal information about you. While you may set your browser to not accept cookies from Questionable.io web sites, some functions of the web sites may not be available when cookies are not accepted.</p>

      <h2>How we manage personal information</h2>

      <p>Questionable.io undertakes all reasonable steps to ensure that all personal information collected by us is accurate, up-to-date, complete and stored securely on a system that is not accessible directly from public networks.</p>

      <p>When your personal information is no longer required for the purposes which Questionable.io uses personal information, all reasonable steps will be undertaken to securely destroy that information.</p>

      <h2>How your personal information will be used</h2>

      <p>Questionable.io may use your personal information for any of the following purposes:</p>

      <ul>
        <li>Marketing Questionable.io services;</li>
        <li>Fulfilling subscriptions to Questionable.io services;</li>
        <li>Providing updates about Questionable.io services;</li>
        <li>Responding to enquiries about Questionable.io services; and</li>
        <li>Fulfilling all legal requirements of Questionable.io with respect to your personal information.</li>
      </ul>

      <h2>Disclosure of personal information to third parties</h2>

      <p>Questionable.io considers personal information we collect about you to be confidential and will not disclose such information to third parties except in the following circumstances:</p>

      <ul>
        <li>Where you have consented to your personal information being provided to a third party;</li>
        <li>Where Questionable.io is required by law to disclose your information; and</li>
        <li>Where Questionable.io discloses personal information to the related entities of Questionable.io.</li>
      </ul>

      <h2>Correcting your personal information held by Questionable.io</h2>

      <p>Questionable.io appreciates you informing us if you have reason to believe that personal information we hold about you is incorrect, incomplete or not updated.</p>

      <h2>Privacy office contact details</h2>

      <p>Should you have any queries in relation to the above or require further information regarding Questionable.io’s collection, use and storage of your personal information please do not hesitate to contact us at hello@questionable.io</p>
    </div>
  )
}

export default PrivacyPolicy
