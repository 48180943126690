import React from 'react'
import DocumentLayout from '../../DocumentLayout'
import Centered from '../../Centered'
import PrivacyPolicy from '../../shared/ui/PrivacyPolicy'

const PrivacyPolicyPage = () => (
  <DocumentLayout className="subpage-white">
    <Centered>
      <br />
      <PrivacyPolicy />
      <br />
    </Centered>
  </DocumentLayout>
)

export default PrivacyPolicyPage
